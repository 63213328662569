import React from "react"
import Layout from "../components/Layout"
import { Link } from 'gatsby';

const NotFoundPage = () => (
  <Layout darkText titlePage="Not found">
    <h1>NOT FOUND</h1>
    <p>Not a valid URL</p>
    <Link to="/">Go Home</Link>
  </Layout>
)

export default NotFoundPage
